  <!--===== FOOTER =====-->
<footer class="footer">
    <p class="footer__title">Kinson</p>
    <div class="footer__social">
        <a target="_blank" href="http://www.linkedin.com/in/michel-kinson" class="footer__icon"><i class='bx bxl-linkedin' ></i></a>
        <a target="_blank" href="https://twitter.com/kinson_michel" class="footer__icon"><i class='bx bxl-twitter' ></i></a>
        <a target="_blank" href="https://github.com/King07" class="footer__icon"><i class='bx bxl-github' ></i></a>
    </div>
    <p>&#169; {{currentYear}} copyright all right reserved</p>
</footer>
