      <!--===== SKILLS =====-->
      <section class="skills section" id="skills">
        <h2 class="section-title">Skills</h2>

        <div class="skills__container bd-grid">
            <div id="skills__text">
                <h2 [ngsReveal]="getOptions()" class="skills__subtitle">Profesional Skills</h2>
                <p [ngsReveal]="getOptions()" class="skills__text">
                  Over the years, I have worked with many incredible people,
                  and I have gotten the chance to learn and sharpen my skills along the way.
                </p>
                <div [ngsRevealSet]="getOptions()" [ngsInterval]="200" [ngsSelector]="'.skills__data'" *ngFor="let skill of firstSkillSubset" class="skills__data">
                    <div class="skills__names">
                        <i class='{{skill.icon}} skills__icon'></i>
                        <span class="skills__name">{{skill.name}}</span>
                    </div>
                    <div class="skills__bar" [style.width.%]="skill.rating">

                    </div>
                    <div>
                        <span class="skills__percentage">{{skill.rating}}%</span>
                    </div>
                </div>
            </div>

            <div>
              <div *ngFor="let skill of secondSkillSubset" class="skills__data">
                <div class="skills__names">
                    <i class='{{skill.icon}} skills__icon'></i>
                    <span class="skills__name">{{skill.name}}</span>
                </div>
                <div class="skills__bar" [style.width.%]="skill.rating">

                </div>
                <div>
                    <span class="skills__percentage">{{skill.rating}}%</span>
                </div>
            </div>
            </div>
        </div>
    </section>

