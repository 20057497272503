import { Component, OnInit } from '@angular/core';
import { interval } from 'rxjs';
import { getOptions } from "../scroll.config";

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit {


  quality = 'I\'m a software engineer';
  qualities = [
    'A software engineer',
    'I hope for nothing',
    'I fear nothing',
    'I am free '
  ];
  constructor() {
    console.log(this.getOptions())
    console.log(this.getOptionsWith({delay: 400}))
  }

  ngOnInit(): void {
    // emit value in sequence every 1 second
    const source = interval(1000);
    source.subscribe((() => this.quality = this.qualities[this.randomInteger(0, this.qualities.length - 1)]));
  }

  randomInteger(min: number, max: number): number {
    return Math.floor(Math.random() * (max - min + 1)) + min;
  }

  getOptions() {

    return getOptions()
  }

  getOptionsWith(kv: any) {
    return Object.assign(this.getOptions(), kv);
  }

}
