<app-header></app-header>
  <main class="l-main">
    <app-home></app-home>
    <app-about></app-about>
    <app-skills></app-skills>
    <app-projects></app-projects>
    <app-contact></app-contact>
    <app-footer></app-footer>
  </main>

