import { AfterViewInit, Component, ElementRef, OnInit, ViewChild } from '@angular/core';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit, AfterViewInit {

  @ViewChild('nav_toggle') toggle!: ElementRef;
  @ViewChild('nav_menu') nav!: ElementRef;
  constructor(private el: ElementRef) {

  }
  ngAfterViewInit(): void {
    // Create a condition that targets viewports at least 768px wide
    const mediaQuery = window.matchMedia('(max-width: 768px)')
    // Register event listener
    mediaQuery.addListener((e) => this.handleTabletChange(e, this.toggle, this.nav))

    // Initial check
    this.handleTabletChange(mediaQuery, this.toggle, this.nav)
  }

  ngOnInit(): void {
  }

  handleTabletChange(e: any, toggle: ElementRef, nav: ElementRef) {
    // Check if the media query is true
    if (e.matches) {
      // Then log the following message to the console
      console.log("showMenu")

      console.log(toggle)
      console.log(nav)
      if(toggle && nav){
        console.log("showMenu 1")
          toggle.nativeElement.addEventListener('click', () => {
              console.log("showMenu 2")
              nav.nativeElement.classList.toggle('show')
          })
      }

      let navLink = this.el.nativeElement.querySelectorAll('.nav__link');
      navLink.forEach((n: any) => n.addEventListener('click', () => {
        /*Active link*/
        navLink.forEach((n: any) => n.classList.remove('active'));
        n.classList.add('active');

        /*Remove menu mobile*/
        if(nav){
          nav.nativeElement.classList.remove('show')
        }
      }));
    }


  }

}
