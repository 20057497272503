import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AlertComponent } from '../alert/alert.component';
import { MailerService } from '../mailer.service';
import { getOptions } from "../scroll.config";

@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.css']
})
export class ContactComponent implements OnInit {

  hasErrors = false;
  isCompleted = false;
  contactMe: FormGroup;
  private mailService: MailerService;
  constructor(private fb: FormBuilder, mailService: MailerService, private modalService: NgbModal) {
    this.mailService = mailService;
    this.contactMe = this.fb.group({
      name: ["", Validators.required],
      email: ["", [Validators.required, Validators.email]],
      message: ["", Validators.required]
  });
  }

  ngOnInit(): void {
  }

  sendMessage() {
    const modalRef = this.modalService.open(AlertComponent, { centered: true, backdrop: 'static', keyboard: false });
    modalRef.componentInstance.response = {
      success: false,
      from: "",
      message: ""
    };
    let data = JSON.stringify({
      name: this.contactMe.value.name,
      email: this.contactMe.value.email,
      message: this.contactMe.value.message,
  })
    console.log(data)

    this.mailService.postMessage(data).subscribe(
      (res) => {
        if (res !== undefined) {
          this.isCompleted = true;
          modalRef.componentInstance.response = {
            success: true,
            from: this.contactMe.value.name,
            message: "Successfully sent"
          };
          this.contactMe.patchValue({name: "", email: "", message: ""});
          console.log("Successfully sent")
        }
      },
      (error) => {
        this.hasErrors = true;
        modalRef.componentInstance.response = {
          success: false,
          from: this.contactMe.value.name,
          message: "Failed"
        };
        console.log(error);
      });
  }

  getOptions() {
    return getOptions()
  }

  getOptionsWith(kv: any) {
    return Object.assign(this.getOptions(), kv);
  }

}
