      <!--===== HOME =====-->
      <section class="home bd-grid" id="home">
        <div class="home__data">
            <h1 class="home__title" [ngsReveal]="getOptions()">Hi,<br>I'm <span class="home__title-color">Kinson</span><br>{{quality}}</h1>

            <a href="#contact" class="button" [ngsReveal]="getOptionsWith({delay: 200})">Contact</a>
        </div>

        <div class="home__social" [ngsRevealSet]="getOptions()" [ngsInterval]="200" [ngsSelector]="'.home__social-icon'">
            <a target="_blank" href="http://www.linkedin.com/in/michel-kinson" class="home__social-icon"><i class='bx bxl-linkedin'></i></a>
            <a target="_blank" href="https://twitter.com/kinson_michel" class="home__social-icon"><i class='bx bxl-twitter' ></i></a>
            <a target="_blank" href="https://github.com/King07" class="home__social-icon"><i class='bx bxl-github' ></i></a>
        </div>

        <div class="home__img" [ngsReveal]="getOptionsWith({delay: 400})">
            <img src="assets/img/perfil__.png" alt="">
        </div>
    </section>
