import { Component, OnInit, Inject } from '@angular/core';
import { getOptions } from "../scroll.config";
import { Project, ProjectDetailComponent } from '../project-detail/project-detail.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-projects',
  templateUrl: './projects.component.html',
  styleUrls: ['./projects.component.css']
})
export class ProjectsComponent implements OnInit {

  projects = [
    {
      name: "Swipe 2 Cook (Recipe App)",
      img: "assets/img/sc.png",
      progress: 40
    },
    {
      name: "Football App",
      img: "assets/img/football.jpg",
      progress: 40
    },
    {
      name: "Tutoring App",
      img: "assets/img/tutor.png",
      progress: 10
    }
  ]
  constructor(private modalService: NgbModal) { }
  ngOnInit(): void {
  }

  open(project: Project) {
    const modalRef = this.modalService.open(ProjectDetailComponent, { centered: true });
    modalRef.componentInstance.project = project;
  }

  getOptions() {
    return getOptions()
  }

  getOptionsWith(kv: any) {
    return Object.assign(this.getOptions(), kv);
  }

}
