import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { Observable } from "rxjs";
@Injectable({
  providedIn: 'root'
})
export class MailerService {

  private http: HttpClient;
  private url = "https://europe-west1-mailing-299613.cloudfunctions.net/sendMail"
  constructor(http: HttpClient) {
    this.http = http;
  }

  postMessage(message: any): Observable<HttpResponse<Object>> {
    return this.http.post(this.url, message, {
        reportProgress: true,
        observe: "response",
        responseType: 'text',
        headers: {
          'Content-Type': 'application/json'
        }
    });
}
}
