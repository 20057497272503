import { Component, OnDestroy, OnInit } from '@angular/core';
import {NgsRevealConfig, NgsRevealService} from 'ngx-scrollreveal';
import { Subscription } from 'rxjs';
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css'],
  providers: [NgsRevealConfig]
})
export class AppComponent implements OnInit, OnDestroy{
  title = 'portfolio';
  //keep refs to subscriptions to be able to unsubscribe later
  private beforeRevealSubscription!: Subscription;
  private afterRevealSubscription!: Subscription;
  private beforeResetSubscription!: Subscription;
  private afterResetSubscription!: Subscription;
  constructor(config: NgsRevealConfig, private revealService: NgsRevealService) {
    // customize default values of ngx-scrollreveal directives used by this component tree
    // config.origin = 'top'
    // config.distance = '80px'
    // config.duration = 2000;
    // config.cleanup = true;
    // config.reset = true;
  }

  ngOnInit() {
    // subscribe to ScrollReveal observables to react to main events
    this.beforeRevealSubscription = this.revealService.beforeReveal$.subscribe(
      (el: HTMLElement) => {
        // console.log(`beforeReveal of '<${el.nodeName}>.${el.className}'`);
      });

    this.afterRevealSubscription = this.revealService.afterReveal$.subscribe(
      (el: HTMLElement) => {
        // console.log(`afterReveal of '<${el.nodeName}>.${el.className}'`);
    });

    this.beforeResetSubscription = this.revealService.beforeReset$.subscribe(
      (el: HTMLElement) => {
        // console.log(`beforeReset of '<${el.nodeName}>.${el.className}'`);
    });

    this.afterResetSubscription = this.revealService.afterReset$.subscribe(
      (el: HTMLElement) => {
        // console.log(`afterReset of '<${el.nodeName}>.${el.className}'`);
    });
  }

  ngOnDestroy() {
    // unsubscribe to ScrollReveal observables to prevent memory leaks
    this.beforeRevealSubscription.unsubscribe();
    this.afterRevealSubscription.unsubscribe();
    this.beforeResetSubscription.unsubscribe();
    this.afterResetSubscription.unsubscribe();
  }
}
