<div class="modal-header">
  <h4 class="modal-title">Thank you for your message {{response.from}}</h4>
  <ng-template [ngIf]="response.success">
    <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </ng-template>
</div>
<div class="modal-body">
    <ng-template [ngIf]="response.from == ''">
      <div><strong>Sending.......</strong></div>
    </ng-template>
    <ng-template [ngIf]="response.success">
      <div class="alert alert-success">
        <strong>Success!</strong> Your message was sent successfully.
      </div>
    </ng-template>
    <ng-template [ngIf]="!response.success && response.from != ''">
      <div class="alert alert-danger">
        <strong>Failed!</strong> Something went wrong. Try again later.
      </div>
    </ng-template>
  <input #input_ hidden />
</div>
<ng-template [ngIf]="response.success">
  <div class="modal-footer">
    <button type="button" class="btn btn-outline-dark" (click)="activeModal.close('Close click')">Close</button>
  </div>
</ng-template>

<ng-template [ngIf]="!response.success && response.from != ''">
  <div class="modal-footer">
    <button type="button" class="btn btn-outline-dark" (click)="activeModal.close('Close click')">Close</button>
  </div>
</ng-template>
