import { Component, OnInit } from '@angular/core';
import { getOptions } from "../scroll.config";
@Component({
  selector: 'app-about',
  templateUrl: './about.component.html',
  styleUrls: ['./about.component.css']
})
export class AboutComponent implements OnInit {
  introduction = 'I\'m Kinson'
  sub_introduction = [
    'I hope for nothing',
    'I fear nothing',
    'I am free '
  ];
  constructor() { }

  ngOnInit(): void {
  }

  getOptions() {
    return getOptions()
  }

  getLastestWorkingYears() {
    return new Date().getFullYear() - 2017
  }

  getOptionsWith(kv: any) {
    return Object.assign(this.getOptions(), kv);
  }

}
