<!--===== ABOUT =====-->
<section class="about section " id="about">
  <h2 class="section-title">About</h2>

  <div class="about__container bd-grid">
      <div class="about__img" [ngsReveal]="getOptions()">
          <img src="assets/img/about_.png" alt="">
      </div>

      <div id="about__text" [ngsRevealSet]="getOptionsWith({delay: 400})" [ngsSelector]="'.about__text'">
          <h2 [ngsReveal]="getOptionsWith({delay: 400})" class="about__subtitle">{{introduction}}</h2>
          <blockquote>
            <ul [ngsRevealSet]="getOptionsWith({delay: 400})" [ngsSelector]="'.about__quote'" class="about__quote" *ngFor="let sub_intro of sub_introduction"><b>{{sub_intro}}</b></ul>
            <span class="about__text">— Nikos Kazantzakis</span>
          </blockquote>
          <p class="about__text">
            As a kid in school, Math always intrigued me.
            Unlike other subjects like History and Social studies, Math is based on logic.
            You don't have to study a textbook word by word, its more about understanding the concepts.
            So throughout my life, Math is always at the forefront because from my perspective,
            it is the most useful subject in life. Math is nature's language to us.
          </p>
          <p class="about__text">
            As Math always at the forefront of my life, after high school,
            I went for a bachelor degree in <a target="_blank" href="https://www.inholland.nl/inhollandcom/bachelors-degrees/mathematical-engineering-en-fulltime/">Mathematical engineering</a> in Amsterdam
            because I didn't have any idea of what I wanted to do as a career.
            During this study, I did an internship as a software developer.
            From there, I fell in love with software development and went on to get a master degree in <a target="_blank" href="https://www.uva.nl/en/programmes/masters/software-engineering/software-engineering.html">software engineering</a>.
          </p>
          <p class="about__text">
            Currently, I am working at <a target="_blank" href="http://www.bol.com">bol.com</a> as a senior software engineer.
            I have been there for the past {{getLastestWorkingYears()}} years.
            I am part of a team that is responsible for telling the world what <a target="_blank" href="http://www.bol.com">bol.com</a> is selling through search engine advertising (SEA).
          </p>
      </div>
  </div>
</section>
