import { Component, OnInit } from '@angular/core';
import { getOptions } from "../scroll.config";

@Component({
  selector: 'app-skills',
  templateUrl: './skills.component.html',
  styleUrls: ['./skills.component.css']
})
export class SkillsComponent implements OnInit {

  public skills = [
    {
      name: "Team player",
      rating: 99,
      icon: "bx bx-network-chart"

    },
    {
      name: "Java Spring Boot",
      rating: 80,
      icon: "bx bxl-spring-boot"

    },
    {
      name: "Streaming and batch application",
      rating: 80,
      icon: "bx bx-strikethrough"

    },
    {
      name: "Google cloud services",
      rating: 50,
      icon: "bx bxl-google-cloud"

    },
    {
      name: "Kubernetes",
      rating: 50,
      icon: "bx bxl-kubernetes"

    },
    {
      name: "Software testing (Spock, JUnit)",
      rating: 80,
      icon: "bx bx-test-tube"

    },
    {
      name: "Gitlab ci deployment",
      rating: 80,
      icon: "bx bxl-gitlab"

    },
    {
      name: "Web development (HTML, CSS, JS)",
      rating: 79,
      icon: "bx bxl-html5"

    },
    {
      name: "Anjular & Typescript",
      rating: 60,
      icon: "bx bxl-angular"

    },
    {
      name: "Andoid mobile development",
      rating: 50,
      icon: "bx bx-mobile"

    }
  ]

  public firstSkillSubset: any
  public secondSkillSubset: any
  constructor() {
    this.skills.sort((a, b) => (a.rating < b.rating) ? 1 : -1)
    let middle = Math.floor(this.skills.length/2)
    this.firstSkillSubset = this.skills.slice(0, middle)
    this.secondSkillSubset = this.skills.slice(middle)
  }

  ngOnInit(): void {
  }

  getOptions() {
    return getOptions()
  }

  getOptionsWith(kv: any) {
    return Object.assign(this.getOptions(), kv);
  }

}
