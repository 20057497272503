<!--===== CONTACT =====-->
<section class="contact section" id="contact">
  <h2 class="section-title">Contact</h2>
  <div class="contact__container bd-grid" [ngsRevealSet]="getOptions()" [ngsSelector]="'.contact__input'">

      <form [formGroup]="contactMe" class="contact__form">
        <h4 [ngsReveal]="getOptions()" *ngIf="contactMe.invalid || contactMe.untouched">Please fill in your name, email and a message.</h4>
          <input type="text" placeholder="Name" name="name" formControlName="name" class="contact__input" required>
          <input type="email" placeholder="Email" name="email" formControlName="email" class="contact__input" required>
          <textarea name="message" formControlName="message" cols="0" rows="10" class="contact__input" required></textarea>
          <input
          [ngsReveal]="getOptionsWith({delay: 200})"
           *ngIf="contactMe.invalid || contactMe.untouched"
           type="button"
           value="Send"
           class="contact__button button"
           disabled>

           <input
           [ngsReveal]="getOptionsWith({delay: 200})"
           *ngIf="contactMe.valid"
           type="button"
           value="Send"
           class="contact__button button"
           (click)="sendMessage()">
      </form>
  </div>
</section>
