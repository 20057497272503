import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
export interface Response {
  success: boolean,
  from: string,
  message: string
}
@Component({
  selector: 'app-alert',
  templateUrl: './alert.component.html',
  styleUrls: ['./alert.component.css']
})
export class AlertComponent implements OnInit {

  @Input() response!: Response
  @ViewChild("input_", { static: false }) input!: ElementRef;
  constructor(public activeModal: NgbActiveModal) {}

  ngOnInit(): void {
    setTimeout(() => this.input.nativeElement.focus(), 0);
  }

  ngAfterViewInit() {
    // this.input.nativeElement.focus();
    // if the above is done in a setTimeout, it no longer scrolls
    setTimeout(() => this.input.nativeElement.focus(), 0);
  }

}
